import React from "react";
import { Link, useParams } from "react-router-dom";
import projectsData from "../data/projects.json";
import categoriesData from "../data/categories.json";
import { Container, Row, Col } from "react-bootstrap";
import MenuBar from "./MenuBar";
import Footer from "./Footer";

const VideoPage = () => {
  const truncateText = (text) => {
    if (text.length > 25) {
      return text.substring(0, 35) + "...";
    }
    return text;
  };

  // Use the category param to fetch the corresponding projects
  const categoryName = "videos";
  const heroImage = categoriesData.find(
    (cat) => cat.categoryName === categoryName
  ).backgroundImage;
  const categoryDesc = categoriesData.find(
    (cat) => cat.categoryName === categoryName
  ).categoryDescription;
  const projects = projectsData.find(
    (cat) => cat.category === categoryName
  ).videos;

  console.log(`test image ${heroImage}`);
  console.log(`test desc ${categoryDesc}`);

  return (
    <Container id="category-container">
      <MenuBar />
      <section id="project-category-page">
        <Row>
          <Col xs={12}>
            <h1 style={{ margin: "20px", textAlign: "center" }}>
              {categoryName} Projects
            </h1>
            <p>{categoryDesc}</p>
          </Col>
        </Row>
      </section>
      <Row style={{ marginBottom: "25em" }}>
        {projects.map((project) => (
          <Col xs={6} style={{ padding: "0" }}>
            <Link to={project.link} style={{ textDecoration: "none" }}>
              <div
                className="projectHeroImageWrapper"
                style={{
                  backgroundImage: `url(../images/videos/${project.photo})`,
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="projectHero-tile-overlay"></div>
                <h2 className="projectHero-text">
                  {truncateText(project.title.split(".").shift())}
                </h2>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
      <Footer />
    </Container>
  );
};

export default VideoPage;
