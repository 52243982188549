/* eslint no-restricted-globals: 0 */

import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import projectsData from "../data/projects.json";
import { useMediaQuery } from "react-responsive";
import { Container, Row, Col } from "react-bootstrap";
import MenuBar from "./MenuBar";
import Footer from "./Footer";

const WritingPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 520px)" });

  const handleModalOpen = (pdfUrl, photo) => {
    if (isMobile) {
      if (confirm(`Are you sure you want to download the file "${photo}?"`)) {
        handleDownload(pdfUrl, photo);
      }
    } else {
      setSelectedPdf(pdfUrl);
      setModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const writingFiles = projectsData.find(
    (proj) => proj.category === "writing"
  ).files;

  console.log(writingFiles);
  const handleDownload = (file, originalFilename) => {
    const link = document.createElement("a");
    link.href = file;
    link.download = originalFilename;
    link.click();
  };

  const truncateText = (text) => {
    if (text.length > 25) {
      return text.substring(0, 35) + "...";
    }
    return text;
  };

  return (
    <Container id="category-container">
      <MenuBar />
      <section id="project-category-page">
        <Row>
          <Col xs={12}>
            <h1 style={{ margin: "20px", textAlign: "center" }}>Writing</h1>
            <p>
              This page is a curated collection of the various write-ups I have
              created throughout my career in the Philippine government sector.
              It features a compilation of press statements and releases that
              demonstrate my skills in effective communication, shaping public
              perception, and conveying critical information with clarity and
              precision. Each piece reflects my dedication to promoting
              transparency and delivering accurate narratives that resonate with
              diverse audiences, particularly the Filipino community. <br />
              <br />
              The following samples highlight my expertise in featured writing,
              media relations, news advocacy, and press releases.
            </p>
          </Col>
        </Row>
      </section>
      <Row className="align-items-center">
        {writingFiles.map((photo, index) => (
          <Col
            xs={12}
            md={4}
            className="justify-content-center"
            style={{ display: "flex" }}
          >
            <div
              className="categoryWrapperWriting"
              onClick={() =>
                handleModalOpen(`../../images/writing/${photo}`, photo)
              }
              title={photo}
            >
              <h2 className="category-text">
                {truncateText(photo.split(".").shift())}
              </h2>
            </div>
          </Col>
        ))}
      </Row>
      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <iframe
              title="writingModal"
              src={selectedPdf}
              frameBorder="0"
              width="100%"
              height="500px"
              style={{ maxWidth: "100%", maxHeight: "100vh" }}
            />
            <button className="modalCloseBtn" onClick={handleModalClose}>
              Close
            </button>
          </div>
        </div>
      )}
      <Footer />
    </Container>
  );
};

export default WritingPage;
